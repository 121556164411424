<script lang="ts">
    // Props
    // export let menuItems: UserControlItem[] = [];

    // Imports
    import { router } from "../router";

    import { loc, locSettings, locLocalization } from "../store/loc.store";
    import { findConfiguration } from "../services/fetch.api";
    import { clickOutside } from "../actions/clickOutside";
    import {
        IconSearch,
        IconUserCircle,
        IconLogIn,
        IconLanguage,
        IconUserLight,
        IconLogOut,
        IconMenuHamburger,
        IconPlus,
        IconCloseBig,
    } from "../consts/icons";
    import { userStore, logout } from "../store/user.store";
    import { showModal_LogInModal } from "../components/modals/ModalManager.svelte";
    import IDInputModal from "./modals/type/IDInputModal.svelte";
    import type { Language } from "shared/lib/localization/loc.types";
    import { getQuery2QueryString, getParam2QueryString } from "../utils/url";
    import { addTrackingEvent } from "../services/tracking.service";
    import { applicationMode } from "../store/mode.store";
    import { platform } from "../MediaQuery.svelte";

    // Interfaces
    interface UserControlItem {
        key: string;
        icon?: string;
        label?: string;
        subMenuItems?: UserControlMenuItem[];
        clickHandler?: (...args: unknown[]) => unknown;
    }

    interface UserControlMenuItem {
        label: string;
        icon?: string;
        spacer?: boolean;
        clickHandler?: (...args: unknown[]) => unknown;
    }

    let menuPositionTop: number;
    let currentlyOpenMenu: string = undefined;

    const setLanguage = (languageKey: Language) => {
        if ($locSettings.language !== languageKey) {
            addTrackingEvent(`switch language ${languageKey}`, `switch language ${languageKey}`);
        }

        locSettings.update((settings) => {
            settings.language = languageKey;
            return settings;
        });

        //close menu
        currentlyOpenMenu = undefined;
    };

    const handleLogin = () => {
        addTrackingEvent("login open modal", "click login button");
        showModal_LogInModal();
    };

    const handleLogout = () => {
        logout();
        currentlyOpenMenu = undefined;
    };

    $: userSubmenuItems =
        $userStore.isLoggedIn === false
            ? []
            : ([
                  {
                      label: $loc.map("locKey_my_area"),
                      icon: IconUserLight,
                      clickHandler: () => {
                          //get query or param queries
                          let queryString = getQuery2QueryString(["id", "hash_id"]);
                          queryString = queryString ? queryString : getParam2QueryString(["id", "hash_id"]);

                          router.push(`/my_configurations/${queryString}`);
                      },
                  },
                  {
                      label: $loc.map("locKey_logout"),
                      icon: IconLogOut,
                      spacer: true,
                      clickHandler: handleLogout,
                  }
              ] as UserControlMenuItem[]);

    let isIDInputModalVisible = false;
    let searchError = false;

    const search = (search: string) => {
        addTrackingEvent("open search modal", "open search bar");
        const hashId = search.replace(/[#]/g, "");
        findConfiguration(hashId)
            .then((state) => {
                addTrackingEvent("search modal sent", "sent request search bar");
                closeIdInputModal();
                router.push(`/configurator/${state.id}/${hashId}`);
            })
            .catch((error) => {
                searchError = true;
                console.error(error);
            });
    };

    let closeIdInputModal = () => {
        isIDInputModalVisible = false;
        searchError = false;
    };

    $: menuItems = [
        {
            key: "search",
            icon: IconSearch,
            label: "",
            subMenuItems: [],
            clickHandler: () => {
                isIDInputModalVisible = true;
            },
        },
        {
            key: "account",
            icon: $userStore.isLoggedIn ? IconUserCircle : IconLogIn,
            label:
                $userStore.isLoggedIn && $platform === "desktop"
                    ? `${$userStore.firstname} ${$userStore.lastname}`
                    : "",
            subMenuItems: userSubmenuItems,
            clickHandler: $userStore.isLoggedIn ? null : () => handleLogin(),
        },
        {
            key: "language",
            icon: IconLanguage,
            label: $locSettings.language,
            subMenuItems: $locLocalization.map(({ language }) => ({
                label: $loc.map(`lang_${language}`),
                clickHandler: () => setLanguage(language),
            })),
        },
    ] as UserControlItem[];

    export let hiddenMenuItems = window.reseller === "tirol" ? ["search", "account"] : [];
</script>

{#if $applicationMode === "standalone"}
    <div class="user-control-component" bind:clientHeight={menuPositionTop}>
        {#each menuItems.filter((menuItem) => !hiddenMenuItems.includes(menuItem.key)) as { key, icon, label, subMenuItems, clickHandler }}
            <div class="item-wrapper">
                <button
                    type="button"
                    class="item {key}"
                    class:open={currentlyOpenMenu === key}
                    on:click={clickHandler || (() => subMenuItems?.length > 0 && (currentlyOpenMenu = key))}
                >
                    {#if icon}
                        {@html icon}
                    {/if}

                    {#if label}
                        {label}
                    {/if}
                </button>

                {#if currentlyOpenMenu === key && subMenuItems && subMenuItems.length > 0}
                    <div
                        class="menu {key} platform-{$platform}"
                        style="top: {menuPositionTop}px"
                        use:clickOutside
                        on:clickOutside={() => (currentlyOpenMenu = undefined)}
                    >
                        {#each subMenuItems as { label, icon, spacer, clickHandler: SubMenuClickHandler }}
                            <button type="button" class="menu-item" on:click={SubMenuClickHandler}>
                                {#if icon}
                                    {@html icon}
                                {/if}

                                {label}
                            </button>
                        {/each}
                    </div>
                {/if}
            </div>
        {/each}
    </div>
    {#if isIDInputModalVisible}
        <IDInputModal
            error={searchError}
            errorMessage={searchError ? $loc.map("locKey_searched_id_not_found") : ""}
            on:close={closeIdInputModal}
            on:modalAction={({ detail }) => search(detail.payload.id)}
        />
    {/if}
{/if}

<style lang="scss" type="text/scss">
    @use "../scss/vars" as *;
    @use "../scss/rem" as *;

    .user-control-component {
        position: relative;
        box-sizing: border-box;
        border-radius: rem($radius);
        color: var(--color-secondary-88);
        display: flex;
        align-items: center;
        gap: rem(12);

        .item-wrapper {
            position: relative;
        }

        .item {
            all: unset;
            position: relative;
            padding: rem(1) 0;
            display: flex;
            align-items: center;
            gap: rem(8);
            border-bottom: 2px solid transparent;
            cursor: pointer;

            font-size: rem(12);
            font-weight: $font-weight-regular;
            color: var(--color-primary-100);

            :global {
                svg {
                    width: rem(20);
                    height: rem(20);
                    path {
                        fill: var(--color-primary-100);
                    }
                }
            }

            &:hover {
                color: var(--color-primary-60);
                :global {
                    svg {
                        path {
                            fill: var(--color-primary-60);
                        }
                    }
                }
            }

            &.open {
                border-bottom: 2px solid var(--signal-dark);
            }

            &.language {
                text-transform: uppercase;
            }
        }

        .menu {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
            background-color: var(--default-background-color);
            padding: rem(4);
            margin: 0;
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: column;
            gap: rem(4);

            &.language,
            &.platform-mobile {
                right: 0;
                left: unset;
            }

            .menu-item {
                all: unset;
                display: flex;
                align-items: center;
                gap: rem(8);
                padding: rem(4);
                color: var(--color-primary-100);
                font-size: rem(12);
                text-transform: none;
                cursor: pointer;

                :global {
                    svg {
                        width: rem(12);
                        height: rem(12);
                        path {
                            fill: var(--color-primary-100);
                        }
                    }
                }

                &:hover {
                    color: var(--color-primary-60);
                    :global {
                        svg {
                            path {
                                fill: var(--color-primary-60);
                            }
                        }
                    }
                }
            }

            .spacer {
                height: rem(12);
            }
        }
    }
</style>
