<script lang="ts">
    import Modal from "../Modal.svelte";
    import type { SelectableAtomItem } from "../../../atoms/SelectableAtomInterface";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import InputAtom from "../../../atoms/InputAtom.svelte";
    import SelectableAtom from "../../../atoms/SelectableAtom.svelte";
    import { loc } from "../../../store/loc.store";

    let name = "";

    let configSaveOptions: SelectableAtomItem[] = [
        {
            id: "1",
            value: "my-profile",
            label: $loc.map("locKey_save_in_my_profile"),
            selected: true,
        },
    ];
</script>

<Modal backdrop={true} let:dispatchModalAction on:modalAction>
    <svelte:fragment slot="header">
        <h2>{$loc.map("locKey_modal_save_config")}</h2>
    </svelte:fragment>

    <form on:submit|preventDefault={() => dispatchModalAction({ action: "save", payload: { name } })}>
        <InputAtom id="name-input" placeholder={$loc.map("locKey_modal_config_name")} bind:value={name}>
            <i slot="prefix" class="zmdi zmdi-email" />
        </InputAtom>

        <SelectableAtom
            items={configSaveOptions}
            let:item
            on:click={(e) => {
                configSaveOptions.forEach((saveOption) => {
                    if (saveOption.selected) {
                        saveOption.selected = false;
                    }

                    if (saveOption.id === e.detail.id) {
                        saveOption.selected = true;
                    }
                });
                configSaveOptions = configSaveOptions;
            }}
        >
            {item.label}
        </SelectableAtom>
    </form>

    <svelte:fragment slot="footer" let:dispatchModalAction let:close>
        <ButtonAtom color="secondary" size="small" on:click={close}>
            {$loc.map("locKey_cancel")}
        </ButtonAtom>

        <ButtonAtom
            color="primary"
            size="small"
            on:click={() => dispatchModalAction({ action: "save", payload: { name } })}
        >
            {$loc.map("locKey_save")}
            <i class="zmdi zmdi-long-arrow-right" />
        </ButtonAtom>
    </svelte:fragment>
</Modal>

<style lang="scss" type="text/scss">
    @use "../../../scss/vars" as *;
    @use "../../../scss/rem" as *;

    form {
        display: flex;
        flex-direction: column;
        gap: rem(15);

        i {
            color: var(--color-text);
        }
    }
</style>
