import Router from "@easyroute/core";
import historyMode from "@easyroute/core/history-mode";
import silentMode from "@easyroute/core/silent-mode";
import Error404 from "./pages/Error404.svelte";
import ErrorNoWebGL from "./pages/ErrorNoWebGL.svelte";
// import Overview from "./pages/Overview.svelte";
import { loading } from "./store/session.store";
import { getSearchParams } from "./utils/url";
import { WebGLCheck } from "./webglCheck";

const isInIframe = getSearchParams().some(({ key, value }) => key === "mode" && value === "iframe");

export const router = new Router({
    mode: isInIframe ? silentMode : historyMode,
    omitTrailingSlash: true, // should we remove the last slash in the url,
    routes: [
        {
            path: "/request/:id/:hash_id",
            component: () => import("./pages/RequestMail.svelte"),
            name: "RequestMail",
        },
        {
            path: "/ui",
            component: () => import("./pages/UI.svelte"),
            name: "UI",
        },
        {
            path: "/order-overview/:id/:hash_id",
            component: () => import("./pages/OrderOverview.svelte"),
            name: "OrderOverview",
        },
        {
            path: "/my_configurations",
            component: () => import("./pages/MyConfigurations.svelte"),
            name: "My Configurations",
        },
        {
            path: "/configurator/:id/:hash_id",
            component: () => import("./pages/Configuration.svelte"),
            name: "3D Configurator",
        },
        {
            path: "/configurator/:id",
            component: () => import("./pages/Configuration.svelte"),
            name: "3D Configurator",
        },
        {
            path: "/",
            component: () => import("./pages/Overview.svelte"),
            name: "Overview",
            beforeEnter: async (to, from, next) => {
                if (import.meta.env.VITE_UI_MODE !== "development") {
                    window.location.replace(`https://hts-tentiq.com/`);
                } else {
                    next();
                }
            },
        },
        {
            path: "/nowebgl",
            component: ErrorNoWebGL,
            name: "ErrorNoWebGL",
        },
        {
            path: "/*",
            component: Error404,
            name: "Error404",
        },
    ],
});

router.beforeEach((to, from, next) => {
    loading.update(($loading) => {
        $loading.page = true;
        return $loading;
    });
    WebGLCheck(to, from, next);
});

router.afterEach((to, from) => {
    loading.update(($loading) => {
        $loading.page = false;
        return $loading;
    });
});
